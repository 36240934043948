import { Link } from "gatsby";
import { navigate } from "@reach/router";

import React, { useState, useContext } from "react";
import mask from "../images/mask.png";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";

import { logout } from "../utils/auth";
import { useFirebase } from "gatsby-plugin-firebase";
import { getUser } from "../utils/auth";
import flagUS from "../images/flagUS.svg";
import flagVN from "../images/flagVN.svg";

function HeaderApp() {
  const [firebase, setFirebase] = useState();
  const [isExpanded, toggleExpansion] = useState(false);
  useFirebase((fb) => {
    setFirebase(fb);
  }, []);
  const dispatch = useContext(GlobalDispatchContext);

  const user = getUser();
  const { displayName } = user;

  return (
    <header className={`fixed w-full bg-indigo-700 `}>
      <div className="flex flex-wrap items-center justify-between p-4 pr-2 mx-auto px-4 container  md:py-4 sm:px-8 ">
        <Link to="/">
          <h1 className="flex items-center text-white no-underline">
            <img src={mask} alt="mask" className="h-10 mr-2"></img>
            <span className="text-xl font-bold tracking-tight hidden sm:block">
              Don Bosco COVID Screening
            </span>
            <span className="text-lg font-bold tracking-tight sm:hidden">
              DB Covid Screening
            </span>
          </h1>
        </Link>
        <button
          className="items-center block px-3 py-2 text-white border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:items-center w-full md:w-auto`}
        >
          <button
            className="block mt-4 md:mt-0 md:inline-block md:ml-3 h-5"
            onClick={() => dispatch({ type: "SET_LANGUAGE", payload: "en" })}
          >
            <img className="h-full" src={flagUS} alt="US" />
          </button>
          <button
            className="block mt-4 md:mt-0 md:inline-block md:ml-3 h-5"
            onClick={() => dispatch({ type: "SET_LANGUAGE", payload: "vn" })}
          >
            <img className="h-full" src={flagVN} alt="VN" />
          </button>

          <Link
            to="/app/profile"
            className="block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-3 hover:text-gray-200 transition duration-100"
          >
            {displayName}
          </Link>
          <a
            className="block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6 hover:text-gray-200 transition duration-100"
            href="/"
            onClick={(event) => {
              event.preventDefault();
              logout(firebase).then(() => navigate(`/app/login`));
            }}
          >
            Sign Out
          </a>
        </nav>
        {/* <nav className="mr-2">
          <span className="text-white text-sm mr-2">{displayName}</span>
          <a
            className=" text-white text-sm no-underline md:inline-block md:mt-0 md:ml-6  bg-gray-500 rounded p-2 hover:bg-gray-400 transition duration-100"
            href="/"
            onClick={(event) => {
              event.preventDefault();
              logout(firebase).then(() => navigate(`/app/login`));
            }}
          >
            Sign Out
          </a>
        </nav> */}
      </div>
    </header>
  );
}

export default HeaderApp;
