import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { isLoggedIn } from "../utils/auth";
import { useFirebase } from "gatsby-plugin-firebase";

import Header from "./header";
import HeaderApp from "./headerApp";
import Footer from "./footer";

import { GlobalStateContext } from "../context/GlobalContextProvider";

function Layout({ children }) {
  const state = useContext(GlobalStateContext) || {};

  const [firebase, setFirebase] = useState(); //eslint-disable-line

  useFirebase((fb) => {
    setFirebase(fb);
  }, []);

  return (
    <div className="flex flex-col min-h-screen font-sans text-gray-900">
      {isLoggedIn() ? (
        <HeaderApp />
      ) : (
        <Header
          headerColor={
            state.submitted && state.success
              ? "green"
              : state.submitted && !state.success
              ? "red"
              : ""
          }
        />
      )}

      {/* <Header
        headerColor={
          state.submitted && state.success
            ? "green"
            : state.submitted && !state.success
            ? "red"
            : ""
        }
      /> */}

      <main
        className={`flex-1 w-full mt-16 ${
          state.submitted && state.success
            ? "bg-green-500"
            : state.submitted && !state.success
            ? "bg-red-500"
            : ""
        }`}
      >
        {children}
      </main>

      <Footer
        footerColor={
          state.submitted && state.success
            ? "green"
            : state.submitted && !state.success
            ? "red"
            : ""
        }
      />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
