import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const Footer = ({ footerColor }) => {
  return (
    <footer
      className={`${
        footerColor === "red"
          ? "bg-red-700"
          : footerColor === "green"
          ? "bg-green-800"
          : "bg-indigo-700"
      } `}
    >
      <nav className="flex justify-between container px-8 p-4 mx-auto md:py-4">
        <p className="text-white text-sm my-auto">
          {`© Don Bosco Austin ${new Date().getFullYear()}`}
        </p>
        <div className="flex flex-col">
          <Link
            className="text-gray-200 hover:text-gray-400 transition duration-150 text-xs my-auto"
            to="/app/login"
          >
            Screener Login
          </Link>
          <Link
            className="text-gray-200 hover:text-gray-400 transition duration-150 text-xs my-auto"
            to="/privacy"
          >
            Privacy Policy
          </Link>
        </div>
      </nav>
    </footer>
  );
};

Footer.propTypes = {
  footerColor: PropTypes.string.isRequired,
};

export default Footer;
