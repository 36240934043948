import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useContext } from "react";
import mask from "../images/mask.png";
import { GlobalDispatchContext } from "../context/GlobalContextProvider";
import flagUS from "../images/flagUS.svg";
import flagVN from "../images/flagVN.svg";

function Header({ headerColor }) {
  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const dispatch = useContext(GlobalDispatchContext);

  return (
    <header
      className={`fixed w-full ${
        headerColor === "red"
          ? "bg-red-700"
          : headerColor === "green"
          ? "bg-green-800"
          : "bg-indigo-700"
      } `}
    >
      <div className="flex flex-wrap items-center justify-between p-4 pr-2 mx-auto px-4 container  md:py-4 sm:px-8 ">
        <Link to="/">
          <h1 className="flex items-center text-white no-underline">
            <img src={mask} alt="mask" className="h-10 mr-2"></img>
            <span className="text-xl font-bold tracking-tight hidden sm:block">
              {site.siteMetadata.title}
            </span>
            <span className="text-lg font-bold tracking-tight sm:hidden">
              DB COVID Screening
            </span>
          </h1>
        </Link>

        <div>
          <button
            className="h-5 mr-2"
            onClick={() => dispatch({ type: "SET_LANGUAGE", payload: "en" })}
          >
            <img className="h-full" src={flagUS} alt="US" />
          </button>
          <button
            className="h-5 "
            onClick={() => dispatch({ type: "SET_LANGUAGE", payload: "vn" })}
          >
            <img className="h-full" src={flagVN} alt="VN" />
          </button>
        </div>

        {/* <button
          className="items-center block px-3 py-2 text-white border border-white rounded md:hidden"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:items-center w-full md:w-auto`}
        >
          {[
            {
              route: `/about`,
              title: `About`,
            },
            {
              route: `/contact`,
              title: `Contact`,
            },
          ].map((link) => (
            <Link
              className="block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6"
              key={link.title}
              to={link.route}
            >
              {link.title}
            </Link>
          ))}
        </nav> */}
      </div>
    </header>
  );
}

export default Header;
